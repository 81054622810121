import React, { Suspense, lazy, useState } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  useMediaQuery,
  CssBaseline,
  LinearProgress,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import MainAppFrame from "./MainAppFrame";
import BottomNav from "./BottomNav";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import useAPI from "./hooks/useAPI";
import OnboardingDialog from "./tools/OnboardingDialog";
import { LocalizationProvider, deDE } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";

const firebaseConfig = {
  apiKey: "AIzaSyBamwlouvxWKrojNG9wZuq0pYkc4-w1AV4",
  authDomain: "kfv-lds.firebaseapp.com",
  projectId: "kfv-lds",
  storageBucket: "kfv-lds.appspot.com",
  messagingSenderId: "1075520789631",
  appId: "1:1075520789631:web:6fed1f3f70b8540f0d734a",
  measurementId: "G-D92NHL69WL",
};

initializeApp(firebaseConfig);

const feuerwehr = require("fast_api");
var defaultClient = feuerwehr.ApiClient.instance;

//lazy loading components bewirkt, dass die Komponenten erste geladen werden, wenn sie das erste mal angezeigt werden
const Neuigkeiten = lazy(() => import("./Neuigkeiten/Neuigkeiten"));
const Feuerwehren = lazy(() => import("./Feuerwehren/Feuerwehren"));
const Kalender = lazy(() => import("./Kalender/Kalender"));
const Fortbildungen = lazy(() => import("./Fortbildungen/Fortbildungen"));
const Auth = lazy(() => import("./Auth/AuthPage"));
const ProfilePage = lazy(() => import("./Auth/ProfilePage"));
const Impressum = lazy(() => import("./tools/Impressum"));
const Datenschutzerklärung = lazy(() => import("./tools/Datenschutzerklärung"));
const Nutzer = lazy(() => import("./Nutzer/Nutzer"));
const PartnerCard = lazy(() => import("./PartnerCard/PartnerCard.jsx"));
const Verleih = lazy(() => import("./Verleih/Verleih.jsx"));

export const AuthContext = React.createContext(null);

const useStyles = makeStyles({
  mobileRoot: {
    marginBottom: "70px",
  },
});

//Home Komponente mit Routing
function Home() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#B51F2A",
          },
          secondary: {
            main: "#1FB5AA",
          },
        },
        components: {
          // Name of the component
          MuiFab: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                position: "fixed",
                bottom: 70,
                right: 16,
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  const [isLoggedIn, setLoggedIn] = useState(null);
  const [user, setUser] = useState(undefined);

  const [auth, setAuth] = useState(getAuth());

  function refreshAuth() {
    setAuth(getAuth());
  }

  const { apiInstance } = useAPI();

  const [showOnboarding, setShowOnboarding] = useState(true);

  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      // Indicates that the state will be persisted even when the browser window
      // is closed or the activity is destroyed in React Native.An explicit sign out
      // is needed to clear that state.Note that Firebase Auth web sessions are single
      // host origin and will be persisted for a single domain only.
      return null;
    })
    .catch((error) => {
      // Handle Errors here.
    });

  function subscribeToPush(reg, retry = false) {
    reg.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BCYnDZgHr2CES2lYim7bGITirHKItrhEEV-xRpwEIMOvlH9AlyfHfYQeYGHLv0bPKCKLEccbBoRA5zLudYJ5QoI",
      })
      .then(function (sub) {
        // during testing phase update once per minute
        apiInstance.Nutzer.subscribeToPushUserSubscribePost(
          JSON.stringify(sub),
          (error, data, response) => {
            if (error) {
              console.error(error);
            }
          }
        );
      })
      .catch(function (e) {
        if (Notification.permission === "denied") {
          console.warn("Permission for notifications was denied");
        } else {
          console.error("Unable to subscribe to push", e);
          // retry once by unsubscribing and resubscribing
          if (retry) {
            reg.pushManager.getSubscription().then(function (subscription) {
              subscription
                .unsubscribe()
                .then(function (successful) {
                  // You've successfully unsubscribed
                  subscribeToPush(reg, false);
                })
                .catch(function (e) {
                  console.error("Unable to unsubscribe ", e);
                });
            });
          }
        }
      });
  }

  onAuthStateChanged(auth, (firebaseUser) => {
    if (firebaseUser) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      let JWTBearer = defaultClient.authentications["JWTBearer"];
      JWTBearer.accessToken = firebaseUser.accessToken;

      if (user === undefined) {
        apiInstance.Nutzer.getCurrentNutzerUserGet((error, data, response) => {
          if (error) {
            console.error(error);
          } else {
            setUser(data);
            setLoggedIn(true);
            if ("serviceWorker" in navigator) {
              navigator.serviceWorker.ready.then(function (reg) {
                subscribeToPush(reg, true);
              });
            }
          }
        });
      }
    } else {
      //user is not signed in
      setUser(null);
      setLoggedIn(false);
      let JWTBearer = defaultClient.authentications["JWTBearer"];
      JWTBearer.accessToken = "";
    }
  });

  let content = null;

  if (user === undefined) {
    content = (
      <Backdrop
        sx={{ color: "#fffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={user === undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    content = (
      <div className={isMobile ? classes.mobileRoot : undefined}>
        <OnboardingDialog
          showDialog={showOnboarding}
          setShowDialog={(show) => setShowOnboarding(show)}
        />
        <Switch>
          <Suspense fallback={<LinearProgress />}>
            <Route exact={true} path="/">
              <Neuigkeiten />
            </Route>
            <Route path="/fortbildungen">
              <Fortbildungen />
            </Route>
            <Route path="/kalender">
              <Kalender calendarID={"71cj0ndf0blit43bjgpc91akd4"} />
            </Route>
            <Route path="/feuerwehren">
              <Feuerwehren />
            </Route>
            <Route path="/konto">
              <ProfilePage />
            </Route>
            <Route path="/login">
              <Auth />
            </Route>
            <Route path="/impressum">
              <Impressum />
            </Route>
            <Route path="/datenschutz">
              <Datenschutzerklärung />
            </Route>
            {user?.role === "admin" && (
              <Route path="/nutzer">
                <Nutzer />
              </Route>
            )}
            <Route path="/partnercard">
              <PartnerCard />
            </Route>
            <Route path="/verleih">
              <Verleih />
            </Route>
          </Suspense>
        </Switch>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Router>
            <AuthContext.Provider
              value={{ isLoggedIn, setLoggedIn, user, refreshAuth }}
            >
              <MainAppFrame showOnboarding={() => setShowOnboarding(true)} />
              {content}
              {isMobile && <BottomNav />}
            </AuthContext.Provider>
          </Router>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default Home;
